@import url("https://use.typekit.net/ecr1coa.css");

/* font-family: "minerva-modern", sans-serif;
font-weight: 400;
font-style: normal;

font-family: "minerva-modern", sans-serif;
font-weight: 400;
font-style: italic;

font-family: "minerva-modern", sans-serif;
font-weight: 700;
font-style: normal;

font-family: "minerva-modern", sans-serif;
font-weight: 700;
font-style: italic; */

/* Sweet Sans Pro Extra Light
font-family: "sweet-sans-pro", sans-serif;
font-weight: 400;
font-style: normal;
Sweet Sans Pro Extra Light Italic
font-family: "sweet-sans-pro", sans-serif;
font-weight: 400;
font-style: italic;
Sweet Sans Pro Medium
font-family: "sweet-sans-pro", sans-serif;
font-weight: 700;
font-style: normal;
Sweet Sans Pro Medium Italic
font-family: "sweet-sans-pro", sans-serif;
font-weight: 700;
font-style: italic; */

.donatePage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.makeYourDonationSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.handsImageContainer > img {
  height: 182px;
}

.donationFormSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 55px;
  background-color: #f7f5eb;
  border-radius: 25px;
  padding: 39px 80px 39px 80px;
  margin-top: 3%;
  width: 75%;
}

.contactSection {
  width: 75%;
}
@media only screen and (max-width: 900px) {
  .donationFormSection {
    padding: 39px 50px 39px 50px;
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .donationFormSection {
    padding: 39px 10px 39px 10px;
    width: 85%;
  }

  .contactSection {
    width: 100%;
  }
}
