@import url("https://use.typekit.net/ecr1coa.css");

.succes-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 10%;
  width: 80%;
  /* height: 100%; */
  height: 600px;

  background-color: #131f67;
  border-radius: 100px;
  color: white;
}

.succes-cont h1 {
  font-family: "minerva-modern", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 3.8rem;
  line-height: 65px;
}

.succes-cont h2 {
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.3rem;
  margin-bottom: 10px;
  margin-top: 25px;
}
.succes-cont h3 {
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.3rem;
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .succes-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 10%;
    width: 90%;
    /* height: 100%; */
    height: 550px;

    background-color: #131f67;
    border-radius: 100px;
    color: white;
  }
}

@media only screen and (max-width: 560px) {
  .succes-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 10%;
    width: 90%;
    /* height: 100%; */
    height: 400px;
    background-color: #131f67;
    border-radius: 50px;
    color: white;
  }
  .succes-cont h1 {
    font-family: "minerva-modern", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.1rem;
    line-height: 37px;
  }

  .succes-cont h2 {
    font-family: "sweet-sans-pro", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .succes-cont h3 {
    font-family: "sweet-sans-pro", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
}

.address-form {
  width: 55vw;
  align-items: center;
  background-color: #f7f5eb;
  border-radius: 25px;
  margin-bottom: 55px;
  margin-top: 3%;
  padding: 39px 80px;
}

.donation-info-title {
  font-size: 3.8rem;
  /* font-weight: 600; */
  margin-bottom: 10px;
  font-family: "minerva-modern", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #131f67;
}