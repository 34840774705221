@import url("https://use.typekit.net/ecr1coa.css");

.footer {
  width: 100%;
  background-color: #131f67;
  justify-content: space-around;
  display: flex;
  bottom: 0;
  height: 315px;
}

.footer-children {
  display: flex;
  width: 75%;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 42%;
  justify-content: center;
  gap: 1rem;
}

.resRelText {
  color: #f6785c;
  font-size: 0.9rem;
  letter-spacing: 2px;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.footerParagraphTextContainer {
  width: 30vw;
}

.resRelText::after {
  content: "TM";
  font-size: 0.4em;
  font-family: "sweet-sans-pro", sans-serif;
  font-size: 0.6em;
  font-style: normal;
  font-weight: 600;
  margin-left: 1px;
  position: relative;
  top: -0.4em;
}

.paragraphTextContainer {
  color: white;
  text-align: left;
  line-height: 2rem;
  /* width: 28rem; */
}

.footerLinksHeaders {
  display: flex;
  color: white;
}

.footerLogo img {
  width: 150px;
  height: auto;
}

.footerRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 18px;
}

.footer-column h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #fff;
  font-family: sweet-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.17em;
  /* margin-bottom: 10px; */
}

.footer-column li {
  display: flex;
  justify-content: flex;
  color: white;
  font-family: "sweet-sans-pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  list-style: none;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.footer-column ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.copyrightTextDesktop {
  color: white;
  font-family: "sweet-sans-pro", sans-serif;
  font-style: normal;
  font-weight: 600;
}
.copyrightTextDesktop {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  position: relative;
  text-align: left;
  margin-bottom: 40px;
  margin-right: 30px;
}

.copyrightTextMobile {
  display: none;
}

.copyrightText p {
  margin: 0;
}

.taxNumber {
  margin: 0;
  color: white;
  line-height: 2rem;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: clamp(1.1rem, 1.2vw, 1.5rem);
}

.footerLine {
  display: none !important;
}

/* @media screen and (max-width: 950px) {
  .footer {
    height: 100%;
    padding: 50px 10px 50px 10px;
  }
  .footerGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 18px;
  }
  .paragraphTextContainer {
    color: white;
    text-align: left;
    line-height: 2rem;
    width: 20rem;
  }
} */

@media screen and (max-width: 800px) {
  .footer {
    height: 100%;
    padding: 50px 10px 50px 10px;
  }

  .copyrightTextMobile {
    display: flex;
    justify-content: flex-start;
    margin-top: 10%;
    font-size: 12px;
    position: relative;
    color: white;
    font-family: "sweet-sans-pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    background-color: #131f67;
  }

  .copyrightTextDesktop {
    display: none;
  }

  .footerRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .footer-children {
    flex-direction: column;
  }

  .footerLine {
    display: block !important;
  }

  .footerLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    gap: 1rem;
  }

  .paragraphTextContainer {
    color: white;
    text-align: left;
    line-height: 2rem;
    /* width: 28rem; */
  }
  /* .copyrightText {
    position: relative;
    top: 0;
    left: 0;
  } */

  .taxNumber {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 460px) {
  .paragraphTextContainer {
    color: white;
    text-align: left;
    line-height: 2rem;
    /* width: 20rem; */
  }
}

/* @media screen and (max-width: 675px) {
  .footerParagraphTextContainer {
    width: 75%;
    text-align: center;
  }

  .footerChildren {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
  }
  .footer {
    flex-direction: column;
    height: 100%;
  }
  .footer-left {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 2%;
  }
  .footer-right {
    width: 84%;
    margin-bottom: 11%;
  }
  .footerGrid {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    column-gap: 50px;
  }
  .footer-left img {
    width: 100px;
    height: auto;
  }
  .footer-left p {
    font-size: 0.9rem;
  }
  .footer-right h3 {
    margin-bottom: 5%;
  }
  .footer-right ul {
    margin-bottom: 5%;
  }
  .footer-right li {
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: #fff;
  }
  .footerLink {
    text-decoration: none;
    color: #fff;
  }
  .footer-bottom {
    color: white;
  }
  .copyContainer {
    margin-top: 5%;
    background-color: #131f67;
    font-size: 0.9rem;
    font-family: sans-serif;
  }
} */
