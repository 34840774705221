@import url("https://use.typekit.net/ecr1coa.css");

/* CONTACT */
.contactFormContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 50px;
}

.contactInfo {
  flex-basis: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2%;
}

.contactInfo h2 {
  font-size: 4.1rem;
  margin-bottom: 0px;
  color: #131f67;
  font-family: "minerva-modern", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 100%;
}

.contactInfo p {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: #131f67;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}

.formContainer {
  flex-basis: 50%;
  /* height: calc(100vh - 10px); */
}

.inputFieldContact {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputFieldContact label {
  font-size: 18px;
  margin-bottom: 10px;
}

.inputFieldContact input {
  padding: 10px 0px;
  border: none;
  font-size: 1rem;
  border-bottom: 1.5px solid #131f67;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.inputFieldContact input {
  -webkit-appearance: none;
  border-radius: 0;
}

.inputFieldContact input:focus {
  outline: none;
  /* border-color: #2d88ff; */
}

.inputFieldContact input::placeholder {
  color: #131f67;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.messageField {
  grid-column: 1 / -1;
}

textarea:focus {
  outline: none;
}

.messageField textarea {
  height: 90px;
  /* text-indent: 10px; */
  font-size: 1rem;
  padding-top: 10px;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  width: 100%;
  border-bottom: 1.5px solid #131f67;
  -webkit-appearance: none;
  border-radius: 0;
  resize: none;
}
.messageField textarea::placeholder {
  margin-left: 10px;
  /* text-indent: 10px; */

  color: #131f67;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  -webkit-appearance: none;
  border-radius: 0;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  font-family: "sweet-sans-pro", sans-serif;
  font-style: normal;
  font-weight: 600;
}

button[type="submit"] {
  background-color: #f6785c;
  color: #fff;
  border: none;
  padding: 10px 65px;
  border-radius: 25px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
}

button[type="submit"]:hover {
  background-color: #ff9d89;
}

.rightArrowSubmit {
  position: relative;
  left: 28px;
  width: 13%;
  top: 2px;
}

@media only screen and (max-width: 768px) {
  .contactFormContainer {
    padding: 20px;
  }
  .contactInfo,
  .formContainer {
    flex-basis: 100%;
    /* margin-bottom: 30px; */
  }

  .contactInfo h2 {
    margin-bottom: -28px;
    line-height: 140%;
  }
}
