@import url("https://use.typekit.net/ecr1coa.css");

.donation-form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: transparent;
  border-radius: 8px;
  margin-top: 0;
  width: 100%;
  outline: none;
}

.donation-form input,
.donation-form select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
}

.payment-methods {
  display: flex;
  flex-direction: column;
}

.donate-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6785c;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  /* padding: 0.5rem 1.9rem ; */
  padding: 10px 90px !important;

  text-decoration: none;
  margin-top: 0;
  width: 100px;
  margin-top: 20px;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600 !important;
  font-style: normal;
}

.rightArrow {
  position: relative;
  left: -30px;
  width: 9px;
  top: 9px;
}

.donate-button:hover {
  background-color: #0056b3;
}

.CardField-input-wrapper {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  width: 90%;

  flex-direction: row;
  border-radius: 25px;
  justify-content: center;
  margin: 10px 0 10px 0;
}

.buttonContainer button {
  margin: 0;
  width: 100%;
  height: 45px;
  padding: 0;
  font-size: 14px;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.button-selected {
  background-color: #131f67;
  color: white;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.left-button {
  border-radius: 25px 0 0 25px;
  border: 2px solid #131f67;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  /* color: #131f67; */
}

.right-button {
  border-radius: 0 25px 25px 0;
  border: 2px solid #131f67;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  /* color: #131f67; */
}

/* CSS for the div with options of 3, 6, and 9 months */
.monthsButtonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* CSS for the buttons (3, 6, 9 months) */
.monthButton {
  flex: 1;
  padding: 10px;
  border: 2px solid #131f67;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
  background-color: transparent;
  color: #131f67;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin: 5px;
  text-transform: uppercase;
}

/* CSS for the selected month button (dark background with light text color) */
.selectedMonthButton {
  flex: 1;
  padding: 10px;
  border: 2px solid #131f67;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
  background-color: #131f67;
  color: #fff;
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin: 5px;
  text-transform: uppercase;
}

.donation-info-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 50px;
}

.donation-info-title {
  font-size: 3.8rem;
  /* font-weight: 600; */
  margin-bottom: 10px;
  font-family: "minerva-modern", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #131f67;
}

.inputMask {
  font-family: "sweet-sans-pro", sans-serif;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  border: none !important;
  border-bottom: 1.5px solid #131f67 !important;
  padding: 0px 0px 5px 0px !important;
  color: #131f67 !important;
  background-color: transparent !important;
  border-radius: 0% !important;
}

.inputMask::placeholder {
  font-size: 0.8rem;
  color: #131f67;
  font-family: "sweet-sans-pro", sans-serif;
  font-style: normal !important;
  font-weight: 600 !important;
}

.inputFieldDonation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
.inputFieldDonation::before {
  content: "$";
  position: absolute;
  left: -2px;
  top: 52%;
  transform: translateY(-50%);
  color: #131f67;
}
.inputFieldDonation input {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  border: none;
  width: 40%;
  margin-right: 10px;
  padding: 0px 0px 0px 10px !important;
  color: #131f67;
  font-weight: 600;

  border-bottom: 1.9px solid #131f67;
}

.inputFieldDonation input::-webkit-inner-spin-button,
.inputFieldDonation input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tdfCVCTurnOffArrows::-webkit-inner-spin-button,
.tdfCVCTurnOffArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputFieldDonation input::placeholder {
  font-size: 0.9rem;
  font-weight: 600;
  color: #131f67;
  letter-spacing: 0.5px;
  font-family: "sweet-sans-pro", sans-serif;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.form-group {
  position: relative;
  outline: none;
  padding: 5px;
}

.form-input {
  width: 100%;
  background: transparent;
  border-bottom: 1px solid blue;
}

.form-input:focus {
  border-color: blue;
}

.line {
  height: 1px;
  background-color: blue;
  margin: 10px 0;
}

.payment-options {
  width: 100%;

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
}

.hide8 {
  display: none;
}

.payment-options-cont {
  margin-bottom: 1.9rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.money-sign {
  position: absolute;
  margin-left: -3px;
  font-size: 1rem;
  margin-top: 90px;
}

.donate-button-cont {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
}

.donationcheckCont {
  margin-top: 2%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.donationcheckCont h1 {
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.9rem;
  margin-left: 4px;
  color: #131f67;
}

.donationcheck {
  width: 2% !important;
  transform: scale(1);
  border-radius: 50%;
  /* border-color: red; */
}

.zelle-cont {
  width: 100%;
}

.zelle-cont h1 {
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: #131f67;
  margin-bottom: 15px;
}

.finalAmount {
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@media only screen and (max-width: 900px) {
  .payment-options {
    width: 100%;
    font-size: 0.8rem;
  }
  .payment-options-cont {
    grid-template-columns: repeat(2, 1fr);
  }

  .donationcheckCont {
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .donationcheck {
    width: 5% !important;
    transform: scale(1);
    border-radius: 50%;
    /* border-color: red; */
  }
}

@media only screen and (max-width: 550px) {
  .monthsButtonContainer {
    /* flex-direction: column; */
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .inputFieldDonation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .inputFieldDonation input {
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    width: 75%;
    /* margin-right: 10px; */
    padding: 0px 0px 0px 1px !important;

    border-bottom: 1.9px solid #131f67;
  }

  /* .finalAmount {
    font-size: 1rem !important;
  } */

  .inputFieldDonation::before {
    content: "$";
    position: absolute;
    left: 20px;
    top: 49%;
    transform: translateY(-172%);
  }

  .zelle-cont h1 {
    font-family: "sweet-sans-pro", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.9rem;
    color: #131f67;
    margin-bottom: 5px;
  }
}

.zelle-qr-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.cc-form {
  border-bottom: #131f67 !important;
  border-width: 2px !important;
  border-style: solid !important;
  outline: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 10px;
}
